<template>
  <div>
    <el-row class="footer">
      <el-col :span="20" :offset="2">
        <el-row>
          <el-col :xs="24" :sm="24" :md="8" :lg="10" :xl="10">
            <div class="image" >
              <el-image
                :src="getImage(detail.path)"
                :fit="'cover'"></el-image>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="14" :xl="14">
            <div class="info">
              <h2>{{detail.name}}</h2>
              <div class="part"><h5>Brand:</h5> <span>{{detail.brand}}</span></div>
              <div class="part"><h5>Availability:</h5> <span>In Stock</span></div>
              <div class="part"><h5>SKU:</h5> <span>30MB-30</span></div>
              <div class="part"><h1>{{detail.price}}</h1></div>
              <el-divider></el-divider>
              <p>
                Curabitur at lacus ac velit ornare lobortis. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Praesent porttitor, nulla vitae posuere iaculis, arcu nisl dignissim dolor, a pretium mi sem ut ipsum. Ut varius tincidunt libero.<br>
                Curabitur at lacus ac velit ornare lobortis. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Praesent porttitor, nulla vitae posuere iaculis, arcu nisl dignissim dolor, a pretium mi sem ut ipsum. Ut varius tincidunt libero.
              </p>
              <el-divider></el-divider>
              <br>
              <el-input-number v-model="num" :min="1" :max="10"></el-input-number>
              <el-button type="danger" @click="count()">Add to card</el-button>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations} from "vuex";
export default {
  name: 'Product',
  data(){
    return{
      data: [
        {id: 1, name: 'Nokia A', path: '1.png', price: '$125', rate: 1},
        {id: 2, name: 'Nokia B', path: '2.png', price: '$225', rate: 5},
        {id: 3, name: 'Nokia C', path: '3.png', price: '$325', rate: 3},
        {id: 4, name: 'Nokia D', path: '4.png', price: '$125', rate: 2},
        {id: 5, name: 'Nokia E', path: '1.png', price: '$225', rate: 1},
        {id: 6, name: 'Nokia F', path: '2.png', price: '$325', rate: 4},
        {id: 7, name: 'Nokia G', path: '3.png', price: '$325', rate: 2},
      ],
      num: 1,
    }
  },
  computed:{
    ...mapGetters({
      detail: "detail",
      products: "products",
      productList: "productList"
    }),
  },
  mounted(){
    console.log(this.$route)
  },
  methods:{
    ...mapMutations([
      "SetProducts",
      "SetProductList"
    ]),
    getImage(value){
      return require(`../assets/phone/${value}`)
    },
    count(){
      this.SetProducts(this.products+this.num);
      for(let i = 0; i < this.num; i++){  
        this.SetProductList(this.detail)
      }
      
    }
  },
}
</script>
<style scoped>
.image{
  background: #f5f5f5;
  padding: 25%;
}
.info{
  text-align: left;
  padding-left: 50px;
}
h2{
  margin: 0px;
  font-weight: 800;
}
h1{
  margin: 0px;
  font-weight: 700;
  color: #ed1d24 !important;
}
h5{
  margin: 0px;
  font-weight: 800;
  width: 150px;
}
.part{
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
</style>